import { supabase } from '../supabaseClient';
import type { SnackFile, Project } from '../types'; // Ensure both types are correctly imported

export class SupabaseProjectService {
    async saveProjectWithFiles(project: Omit<Project, 'user_id' | 'id'>, files: SnackFile): Promise<string> {
        const { data: userData, error: userError } = await supabase.auth.getUser();
        if (userError || !userData) {
            throw new Error('User not authenticated');
        }

        if (!files) {
            throw new Error('Files cannot be null or undefined');
        }

        const { data, error } = await supabase.rpc('upsert_project_and_revision', {
            p_user_id: userData.user.id,
            p_name: project.name,
            p_description: project.description || null,
            p_files: files, 
            p_dependencies: project.dependencies,
            p_sdk_version: project.sdkVersion
        });

        if (error) {
            console.error('Error during project save/update:', error);
            throw error;
        }

        if (!data) {
            throw new Error('Failed to retrieve project ID after save');
        }

        return data;
    }

    private async getLatestRevisionNumber(projectId: string): Promise<number> {
        const { data, error } = await supabase
            .from('revisions')
            .select('version_number')
            .eq('project_id', projectId)
            .order('version_number', { ascending: false })
            .limit(1)
            .single();

        if (error) {
            console.error('Error fetching latest revision number:', error);
            return 0;
        }
        return data?.version_number || 0;
    }

    async loadProject(id: string): Promise<Project> {
        console.log('Querying Supabase for project ID:', id); // Debugging log
        const { data: projectData, error: projectError } = await supabase
            .from('projects')
            .select('*')
            .eq('id', id)
            .single();

        if (projectError) {
            console.error('Supabase error:', projectError); // Debugging log
            throw projectError;
        }
        if (!projectData) {
            console.error('Project not found for ID:', id); // Debugging log
            throw new Error('Project not found');
        }

        // Fetch the latest revision
        const { data: revisionData, error: revisionError } = await supabase // Updated variable name
            .from('revisions')
            .select('*')
            .eq('project_id', id)
            .order('version_number', { ascending: false })
            .limit(1)
            .single();

        if (revisionError) {
            console.error('Error fetching latest revision:', revisionError);
        }

        const projectDependencies = JSON.parse(projectData.dependencies);
        const latestDependencies = revisionData ? JSON.parse(revisionData.dependencies) : null;

        return {
            ...projectData,
            files: JSON.parse(projectData.files),
            dependencies: latestDependencies || projectDependencies,
        };
    }
}